<template>
  <div class="GalleryImageMenuContent">
    <div class="TopContent">
      <div class="TopContentLeft">
        <img :src="item.thumbnail.src" loading="lazy" alt="Image" />
      </div>
      <div class="TopContentRight">
        <div class="Item" v-if="Go.is(item.category, 'Object')">
          <div class="Label">{{ $locale["category"] }}</div>
          <div class="Name" strong>{{ item.category.name }}</div>
          <Spacer num="1" />
        </div>
        <div class="Item">
          <div class="Label">{{ $locale["uploaded"] }}</div>
          <div class="Name" strong>{{ Go.date_locale(item.date, $lang) }}</div>
        </div>
      </div>
    </div>
    <Spacer num="1" bodyBg />
    <div class="Menu">
      <LargeButton :label="$locale['delete_file']" icon="trash" @click="deleteItem(item, (data) => onDeleted(data))" />
    </div>
  </div>
</template>

<script>
import gallery from "./gallery";
export default {
  mixins: [gallery],
  props: ["item"],
  methods: {
    onDeleted: function(data) {
      if (Go.is(this.modal.close, "Function")) {
        this.modal.close(() => {
          data.message && this.alert(data);
          this.$store.state.feedKey++;
        });
      }
    },
  },
};
</script>
